import React, { useEffect, useState } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
//import { storage } from '../firebase'; // Ensure this path is correct

const FileList = ({ sistem }, setLastFile ) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const storage = getStorage();
  const [filenames, setfilenames] = useState([]);

  //Tentando pegar os arquivos do local no Firebase
  useEffect(() => {
    const fetchFiles = async () => {
      const storageRef = ref(storage, 'ArquivosEEG/')
      try {
        const res = await listAll(storageRef)
        const filePromises = res.items.map((itemRef) => getDownloadURL(itemRef))
        const arqnames = res.items.map((itemRef) => itemRef.name)
        const fileUrls = await Promise  .all(filePromises);
        console.log('Fetched file URLs:', fileUrls);
        setFiles(fileUrls);
        console.log('Fetched files names:',arqnames);
        setfilenames(arqnames)
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
    
  }, [storage]);

  if (error) {
    return <div>Error fetching files: {error.message}</div>;
  }

//tem que setar o file tbm
  const handleFileClick = async (fileUrl) => {
  //   console.log(fileUrl);
  //   try {
  //     const response = await fetch(fileUrl);
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const fileContent = await response.text();
  //     console.log(fileUrl);
  //     console.log(fileContent);
  //     setLastFile(fileContent);
  //   } catch (error) {
  //     console.error('Error fetching file content:', error);
  //   }
  };



  return (
    <div>
      <h1>Files in ArquivosEEG Folder</h1>
      <ul>
        {files.map((fileUrl, index) => (
          <li key={index}>
            <a href='#'  onClick={() => handleFileClick(fileUrl)} rel="noopener noreferrer">{filenames[index]}</a>
            
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FileList;
