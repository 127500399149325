import React from 'react'

function Publicacoes() {
  return (
    <div>
      Publicacoes
    </div>
  )
}

export default Publicacoes