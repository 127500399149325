'use strick'
import React from 'react'
import {Link} from 'react-router-dom';
import './Pages_styles/Home.css';
import cover_img from '../Assets/cover_img.png'
import eye from '../Assets/eye.png'
import ihc from '../Assets/ihc.png'
import educacao from '../Assets/educação.png'

function Home() {

  return (
      <div className="App">

        <main>
            <div class="cover_area">
                <div class="cover">
                    <h3 class="university_text"> Universidade Federal da Paraíba </h3>
                    <h1 class="welcome_text"> Bem-vindo ao <br/> Laboratório de Interação e Mídia </h1>
                    <Link to="/Sobre">
                        <button class="saibaMais_btn" type="button">Saiba Mais</button>
                    </Link>
                </div>
                <img class="cover_img" src={cover_img} alt="img_cover" />
            </div>

            
            <div class="area_researches">

            <h2 class='ourResearches'>Nossa Pesquisa</h2>
              <div class="types_researches">
                

                <div class="researches">
                  <img src={ihc} alt="ihc"></img>
                  <p class="researches_title">Interação humano-computador</p>
                  <p class="researches_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore </p>
                </div>

                <div class="researches">
                  <img src={eye} alt="eye"></img>
                  <p class="researches_title">Eye Tracking</p>
                  <p class="researches_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore </p>

                </div>

                <div class="researches">
                  <img src={educacao} alt="educação"></img>
                  <p class="researches_title">Educação</p>
                  <p class="researches_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore </p>

                </div>

              </div>

            </div>
            


        </main>


    </div>
  );
}

export default Home;
