//Components
import React from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import Sobre from './Pages/Sobre';
import Publicacoes from './Pages/Publicacoes';
import Equipe from './Pages/Equipe';
import EegPage from './Pages/EegPage';
import Login from './Pages/Login/index';
// import Map from './Components/Map';

import firebase from './firebase/firebaseConnection';

//imports
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

function App() {

  return (
    <div className="App">
        <main> 

          <Router>
            <Navbar/>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/Sobre" element={<Sobre/>}/>
              <Route path="/Publicacoes" element={<Publicacoes/>}/>
              <Route path="/Equipe" element={<Equipe/>}/>
              <Route path="/EegPage" element={<EegPage/>}/>
              <Route path="/Login" element={<Login/>}/>
            </Routes>
          </Router>
          <Footer/>
        </main>
        
      
    </div>
  );
}

export default App;
