import React from 'react';
import './components_styles/modal.css';
import FileList from './FileList';

const Modal = ({ isOpen, setModalOpen,setLastFile }) => {
  if (isOpen) {
    return (
      <div className='background'>
        <div className='content'>
          <div>
            <FileList setLastFile = {setLastFile} />
          </div>
          <div>
            <button onClick={() => setModalOpen(false)}>Fechar</button>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Modal;
