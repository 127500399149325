import React from 'react';
import './components_styles/video.css';

const Video = () => {
  return (
    <div>
      <video className="video"  controls>
        <source src={`${process.env.PUBLIC_URL}/video_apresentacao.mp4`} type="video/mp4" />
        Seu navegador não suporta a tag de vídeo.
      </video>
    </div>
  );
};

export default Video;
