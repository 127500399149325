// Bug para ser consertado: No select, o modal so abre se a opção selecionada for diferente da que estava antes ou seja se estava openbci e tentar abrir openbci nao vai abrir, mas se estiver openbci e tentar abrir insight abre
import './Pages_styles/EegPage.css'
import axios from 'axios'
import React, {useState} from 'react'
import Papa from 'papaparse'
import Modal from "../Components/Modal"
import brain_img from '../Assets/graph.svg'
import app from '../firebase/firebaseConnection'

const EegPage = () => {
  const [systemType, setSystemType] = useState('openbci'); 
  const [isModalOpen, setModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [eyeImageURL,seteyeImgURL] =  useState('');
  //Importante para as ondas
  const [file, setFile] = useState(null);
  const [lastFile, setLastFile] = useState(null);
  const [eyeFile,setEyeFile] = useState(null);
  const [min, setMinInterval] = useState();
  const [max, setMaxInterval] = useState();
  const [choices, setChoices] = useState({
    af3a: false,
    af3hb: false,
    af4hb: false
  });

  // Lista (OpenBCI ou Insight)
  const systemSelector = (event) => {
    setSystemType(event.target.value);
    setModalOpen(true)
    };

  // Função para ler o arquivo do EEG
  const readFile = (e) => {
    // Receber arquivo
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setLastFile(selectedFile);  //Guarda o último arquivo que foi enviado
  }

  // Função para ler o arquivo do eye tracking
  const readEyeFile = (e) => {
    // Receber arquivo
    const selectedFile = e.target.files[0];
    setEyeFile(selectedFile);
  }

  //Muda a escolha dos checkboxes de onda
  const handleCheckboxChange = (e) => {
    setChoices({ ...choices, [e.target.value]: e.target.checked });
  };

  // Define os intervalos do usuario
  function defineInterval(e){
    if(e.target.name == 'min'){
      setMinInterval(e.target.value);
    }else{
      setMaxInterval(e.target.value);
    }
  }

  // Submeter as informações do gráfico para a API 
  const enviar = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file || lastFile); // Adiciona o arquivo escolhido agora ou o último a ser escolhido.
    console.log(eyeFile);
    formData.append('eyefile', eyeFile);
    formData.append('min', min);
    formData.append('max', max);
    formData.append('choices', JSON.stringify(Object.keys(choices).filter(choice => choices[choice]))); // Adiciona a lista de escolhas como JSON

    // Tentando enviar o arquivo ao backend
    try {
      const response = await axios.post('https://lim.tec.br/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('response data:', response.data)
      setImageUrl(`${response.data.eegImageUrl}?timestamp=${new Date().getTime()}`); //Pega a resposta da API pra setImageUrl
      seteyeImgURL(`${response.data.eyeImageUrl}?timestamp=${new Date().getTime()}`);
    } catch (error) {
      console.error('Error uploading file:', error.response ? error.response.data : error.message);
    }
  }

  return (
    <div>
        <h1 className='title'>Teste aqui seu EEG </h1>
        <p  className='p_text'>Aqui você pode testar seus arquivos de EEG. Escolha um arquivo do seu computador ou escolha um arquivo já existente em nossa base de dados. Além disso, você pode escolher um intervalos de tempo para sua análise e selecionar as ondas que deseja visualizar</p>
        <main className='main'>
          <form className='info_area' onSubmit={enviar}>
            <div className='sub_info_area1'>

              {/* INSERIR ARQUIVO DO EEG*/}
              <div className='box_content'>
                <p className='textSubtitle'>Escolha um arquivo próprio</p>   
                <div className='arquivo_btn_area'>
                    <input type="file"  accept='.csv'  onChange={readFile} className='input_btn' ></input>
                </div>
              </div>

              {/* INSERIR ARQUIVO DO eye tracking*/}
              <div className='box_content'>
                <p className='textSubtitle'>Escolha o arquivo do eye tracking </p>   
                <div className='arquivo_btn_area'>
                    <input type="file"  accept='.csv'  onChange={readEyeFile} className='input_btn' ></input>
                </div>
              </div>

              <div className='box_content'>
                {/* MOSTRAR LISTA DE ARQUIVOS */}
                <p className='textSubtitle'>Arquivo existente</p>
                <div>
                  <select name = 'sistema' value={systemType} onChange={systemSelector} className='custom-select'>
                    <option value='OpenBCI'>OpenBCI</option>
                    <option value='Insight'>Insight</option>
                  </select>
                </div>
               <Modal sistem={systemType} isOpen={isModalOpen} setModalOpen ={setModalOpen} setLastFile = {setLastFile} />
              </div>

                {/* INTERVALO */}
                <div className='box_content'>
                    <p className='textSubtitle'>Timestamp:</p>
                      <div>
                        <input type='number' name='min' placeholder='Min' value={min} onChange={defineInterval} className="custom-input"></input>
                        <input type='number' name='max' placeholder='Max' value={max} onChange={defineInterval} className="custom-input"></input>
                      </div>
                </div>
              {/* sub_info_area1 closing */}
              </div>

              <div className='checkBox_graph_area'>

                <div className='sub_area_info2'>

                  {/* CHECKBOXES */}
                    <div className='checkbox'>
                        <label className='label_checkbox'>Alpha</label>
                        <input type='checkbox' name='ondas'  value= "af3a" onClick={handleCheckboxChange} className='styled_checkbox'></input>
                    </div>

                    <div>
                        <label className='label_checkbox'>High Beta</label>
                        <input type='checkbox'  value= "af3hb" onClick={handleCheckboxChange} className='styled_checkbox'></input>
                    </div>

                    <div>
                        <label className='label_checkbox'>Low Beta</label>
                        <input type='checkbox'  value= "af4hb" onClick={handleCheckboxChange} className='styled_checkbox'></input>
                    </div>

                    {/* BOTAO ENVIAR */}
                    <button type='submit'>enviar</button>

                {/* sub_info_area2 closing */}
                </div>
                <div>
                {/* EEG */}
                <div className='grafico' >
                  {imageUrl ? (<img src={imageUrl} alt='Generated Chart' className='graph-img'/>)
                  : <img src = {brain_img} className='nograph-img'></img> }
                </div>

                {/* EYE TRACKING */}
                <div className='grafico' >
                  {eyeImageURL ? (<img src={eyeImageURL} alt='Generated Chart' className='graph-img'/>)
                  : <img src = {brain_img} className='nograph-img'></img> }
                </div>
                </div>

              {/* checkBox_graph_area closing */}
              </div>
      
          {/* info area closing */}
          </form> 
        </main>





    </div>
  );
}
export default EegPage