import './Pages_styles/Sobre.css';
//import professor from '../Assets/professor_img.png'
import React from 'react'

import Video from '../Components/Video';


function Sobre() {
  return (
    <div className="App">
        <main>
            <div className='conteiner'>

                <div>
                    <Video />                    
                </div>

                <div className='texts_area'>

                    <p className='titulo'>Olá, somos o LIM!</p>
                    <p className='text'>O Projeto de Laboratório de Interação e Mídia (LIM) da Universidade Federal da Paraíba (UFPB) é um espaço de estudo da Interação Humano-Computador, mas que se ramifica numa interdisciplinaridade que tange estudos em Narrativas, Saúde, Design Universal, Ensino e Aprendizado e Jogos.</p>

                    <p className='objetivos'>Objetivos</p>
                    <p className='text'>O objetivo do LIM é contribuir para o avanço do conhecimento em design de interação e mídias digitais, bem como fomentar o desenvolvimento de soluções tecnológicas que promovam uma experiência de uso mais satisfatória para os usuários.</p>
                    
                </div>
            </div>
        </main>
    </div>
   );
}

    export default Sobre;
