//Packeges
import React, {useState} from 'react';
import{Link} from 'react-router-dom';
//Components
import './components_styles/navbar.css';
import Home from '../Pages/Home';
import Sobre from '../Pages/Sobre';
import publicacoes from '../Pages/Publicacoes';
import Equipe from '../Pages/Equipe';
import EegPage from '../Pages/EegPage';
import EnglishHome from '../Pages/EnglishHome';
import ReorderIcon from '@mui/icons-material/Reorder';
import Login from '../Pages/Login/index';
import CloseIcon from '@mui/icons-material/Close';


//Images
import logoLIM from '../Assets/logoLIM.png'

function Navbar() {

  const [openLinks,setOpenLinks] = useState(false);

  //hide menu when screen hits a certain size
  const toggleNavbar = () => {
    setOpenLinks(!openLinks);

    const nav = document.querySelector(".navbar");
    nav.classList.toggle("active");

  if (!openLinks) {
    document.body.classList.add("no-scroll"); // Adiciona a classe para impedir a rolagem
  } else {
    document.body.classList.remove("no-scroll"); // Remove a classe para permitir a rolagem
  }
  
  }
  

  return (
    <div className='navbar'>
      {/* <div className='leftSide' id = {openLinks ? "open" : "close"}>
        <img src={logoLIM}/>
      </div> */}
      <div className='leftSide' >
        <img src={logoLIM}/>
      </div>

      {/* <div className='HiddenLinks'></div> */}

      <div className='rightSide'>
        <div className='btn-links'>
        <Link to="/">Início</Link>
        <Link to="/Sobre">Sobre nós</Link>
        {/*<Link to="/publicacoes">Publicações</Link>*/}
        <Link to="/Equipe">Equipe</Link>
        <Link to="/Login">Ver EEG</Link>
        </div>
        {/* <Link to="/EnglishHome"><img src={USflag}></img></Link> */}
        <button className='buttnAr' onClick={toggleNavbar}>
          {/* <ReorderIcon className='navbarButton'/> */}
          {openLinks ? (
            <CloseIcon className="navbarButton" /> // Ícone de fechar
          ) : (
            <ReorderIcon className="navbarButton" /> // Ícone de abrir
          )}
        </button>



      </div>
    </div>
  )
}

export default Navbar
