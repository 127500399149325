import React, {useEffect, useState } from "react";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import arrowImg from "../../Assets/arrow.svg";
import logoImg from "../../Assets/Logo1.png";
import { auth } from "../../firebase/services/firebaseConfig";
import "./styles.css";
import restritoImg from '../../Assets/restrito.svg'
import EegPage from './../EegPage';
// import firebase from '../../firebase/firebaseConnection'
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();


  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);

    useEffect(() => {
      auth.setPersistence("session")
        .then(() => {
          console.log("Persistência de sessão configurada.");
        })
        .catch((error) => {
          console.error("Erro ao configurar persistência de sessão: ", error);
        });
    }, []);

  function handleSignIn(e) {
    e.preventDefault();
    signInWithEmailAndPassword(email, password);
  }

  if (loading) {
    return <p>carregando...</p>;
  }
  if (user) {
    // Redireciona para a página desejada
    navigate("/EegPage");
    return null; // Evita renderizar o restante do componente após o redirecionamento
  }

  return (
    // <main className="main">
    <div className="container">
      <div className="left">
        <div className="lefsidecont">
          <h1 >Oops, Área restrita</h1>
          <img className="imgLeft" src={restritoImg}></img>
        </div>
      </div>
      <div className="right">
        <header className="header">
          <img src={logoImg} alt="Workflow" className="logoImg" />
          <h1>Laboratório de interação e mídia</h1>
          <h3>Acesse as ferramentas de EEG</h3>
          <span>Por favor, digite suas informações de login</span>
        </header>

        <form className="form">
          <div className="inputContainer">
            <label htmlFor="email">E-mail</label>
            <input
              type="text"
              name="email"
              id="email"
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="inputContainer">
            <label htmlFor="password">Senha</label>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="********************"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          
          <button className="button" onClick={handleSignIn}>
            Entrar <img src={arrowImg} alt="->" />
          </button>
        </form>
      </div>
    </div>
    // </main>
  );
}
export default Login;
