import React, {useEffect,useState} from 'react'
import './Pages_styles/Equipe.css';
//cpmponents
import Perfil from '../Components/Perfil';
//images
import equipeLIM from '../Assets/Equipe LIM.png';
import {db} from '../firebase/services/firebaseConfig'
import { collection, getDocs } from 'firebase/firestore';

function Equipe() {

  const [equipeListaProfessores, setEquipeListaProfessores] = useState([]);
  const [equipeListaAlunos, setEquipeListaAlunos] = useState([]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const participantesSnapshot = await getDocs(collection(db, "participantes")); 
        const professores = [];
        const alunos = [];
        
        participantesSnapshot.docs.forEach(doc => {
          const data = doc.data();
          if (data.cargo === "Professor" || data.cargo === "Coordenador") {
            professores.push({ nome: data.nome, cargo: data.cargo, fotoURL:data.fotoURL });
          } else if (data.cargo === "Aluno") {
            alunos.push({  nome: data.nome, cargo: data.cargo, fotoURL:data.fotoURL  });
          }
          console.log(professores);
          console.log(alunos);


        });


        setEquipeListaProfessores([professores]);
        setEquipeListaAlunos(alunos);
      } catch (error) {
        console.error("Erro ao buscar dados: ", error);
      }
    };

    fetchData();
  }, []);




  return (
    <div className='caixa'>
      <img className='teamImage' src={equipeLIM}/>
      <main>

        <div className='professors_area'>

          <div className='area_professores'>
            <h1 className='professors_title'>Professores</h1>
          </div>

          <div className='area_equipe'>
            <Perfil equipe_lista = {equipeListaProfessores}/>
          </div>

          <div>
            <h1 className='professors_title'>Alunos</h1>
          </div>

          <div>
            <Perfil equipe_lista = {equipeListaAlunos}/>
          </div>

        </div>
        
      </main>

    </div>
  );
}

export default Equipe
