//Packeges
import React, {useState} from 'react';
import{Link} from 'react-router-dom'
//Components
import './components_styles/footer.css';

//Images
import logo1 from '../Assets/Logo1.png'
import instagram from '../Assets/instagram.svg'
import linkedin from '../Assets/linkedin.svg'
import location from '../Assets/location.svg'

function Footer() {

    return (
      <div className='footer'>
        {/* <div> */}
        <div className='footer_leftSide'>
            <div className='university'>
            <img src={logo1} className='logo'/>
              <div className='uni-texts'>
                <p class="texts">Laboratório de Interação e Mídia 2024</p>
                <p class="texts">Universidade Federal da Paraíba</p>
                <p class="texts">Centro de Informática</p>
              </div>
            </div>

            <div className='address'>
              <img src={location} className='location'></img>
              <div className='uni-texts'>
                <p class="texts">Rua dos Escoteiros s/n</p>
                <p class="texts">Mangabeira, João Pessoa - PB, CEP 58055-000</p>
              </div>
            </div>

          </div>

          <div/>
            <div className='social_media'>
              <a href='https://instagram.com/lim.ufpb'><img alt="Instagram" src={instagram}/></a>
              <a href='https://www.linkedin.com/company/ufpblim/'><img alt="Linkedin" src={linkedin}/></a>
            </div>
          <div/>

        {/* </div> */}

        {/* <div className='footer_rightSide'>
          <iframe  className="maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.6856973679924!2d-34.817208099999995!3d-7.162287199999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7acc300395ef2d1%3A0x5cad05c1cf9b09e6!2sCentro%20de%20Inform%C3%A1tica%20-%20UFPB!5e0!3m2!1spt-BR!2sbr!4v1718937594554!5m2!1spt-BR!2sbr"></iframe>
        </div> */}
      </div>
    );
  }

  export default Footer