import React from 'react'
import './components_styles/Perfil.css'

function Perfil({equipe_lista}) {
//criar os perfis a partir da lista de objetos com as informações.
  return (
    <div className='perfil'>    
            {equipe_lista.map((item, index) => (
            <div key={index} className='containers'>
                <img src={item.fotoURL} className='images' alt='Imagem de Perfil' />
                <div className='texts'>
                  <h3 className='name'>{item.nome}</h3>
                  {/* <h3 className='subtitle'>{item.cargo}</h3> */}
                </div>
            </div>
            ))}
    </div>
  )
}

export default Perfil;


