import React from 'react'

function EnglishHome() {
  return (
    <div>
      English Home page
    </div>
  )
}

export default EnglishHome